import React from 'react';
import logo from './img/icon.png';
import screen from './img/iphone-xs-max1.png';
import screen1 from './img/iphone-xs-max2.png';
import screen2 from './img/iphone-xs-max3.png';
import screen3 from './img/iphone-xs-max4.png';
import screen4 from './img/iphone-xs-max5.png';
import screen5 from './img/iphone-xs-max6.png';
import screen6 from './img/iphone-xs-max7.png';
import android from './img/android1.jpg';
import android1 from './img/android2.jpg';
import android2 from './img/android3.jpg';
import android3 from './img/android4.jpg';
import android4 from './img/android5.jpg';
import android5 from './img/android6.jpg';
import android6 from './img/android7.jpg';

function App() {
	return (
		<div style={styles.container}>
			<header style={styles.header}>
				<img style={styles.logo} src={logo} alt="News-Tabs logo" />
				<h1>News-Tabs</h1>
			</header>
			<h2 style={styles.center}>News-Tabs app</h2>
			<h4 style={styles.center}>Available on</h4>
			<div style={styles.center}>
				<a style={styles.button} href='https://play.google.com/store/apps/details?id=com.amir5000.newstabs&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
					<img style={styles.img} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/>
				</a>
				<p>Coming Soon to the Apple App store.</p>
			</div>
			<section style={styles.section}>
				<p style={styles.center}>The top headlines from across the internet with the ability to share stories natively, save stories to read later, and to read the stories in the app.</p>
				<h2 style={styles.center}>Android Screenshots</h2>
				<div style={styles.col}>
					<img style={styles.screens} src={android} alt="android" />
					<img style={styles.screens} src={android1} alt="android 1" />
					<img style={styles.screens} src={android2} alt="android 2" />
					<img style={styles.screens} src={android3} alt="android 3" />
					<img style={styles.screens} src={android4} alt="android 4" />
					<img style={styles.screens} src={android5} alt="android 5" />
					<img style={styles.screens} src={android6} alt="android 6" />
				</div>
				<h2 style={styles.center}>iOS Screenshots</h2>
				<div style={styles.col}>
					<img style={styles.screens} src={screen} alt="screen" />
					<img style={styles.screens} src={screen1} alt="screen 1" />
					<img style={styles.screens} src={screen2} alt="screen 2" />
					<img style={styles.screens} src={screen3} alt="screen 3" />
					<img style={styles.screens} src={screen4} alt="screen 4" />
					<img style={styles.screens} src={screen5} alt="screen 5" />
					<img style={styles.screens} src={screen6} alt="screen 6" />
				</div>
				<div style={styles.bottomSection}>
					<h3>Contact Us</h3>
					<p>To contact us you can email us at <a href="mailto:support@news-tabs.com">support@news-tabs.com</a></p>
				</div>
			</section>
		</div>
		
	);
}

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		justifyContent: 'center',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderBottomColor: '#999',
		borderBottomStyle: 'solid',
		width: '100%',
	},
	logo: {
		width: 50,
		height: 50,
		marginRight: 10,
	},
	button: {
		width: 200,
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	img: {
		width: '100%',
		display: 'block',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		maxWidth: 1350,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	center: {
		textAlign: 'center',
	},
	col: {
		marginTop: 25,
		marginBottom: 25,
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'center',
		alignItems: 'stretch',
		width: '100%',
	},
	screens: {
		width: 305,
		margin: 12,
		borderColor: '#333',
		borderStyle: 'solid',
		borderWidth: 4,
		borderRadius: 10,
	},
	bottomSection: {
		padding: 24,
	},
}

export default App;
